<template>
  <div class="">
    <navbar title="答题"></navbar>
    <div class="box">
      <!-- 大标题 -->
      <div class="title">
        <div class="title-detail">{{ examName }}</div>
      </div>
      <!-- 分割线 -->
      <div class="line"></div>
      <!-- 答题部分 -->
      <div class="sign">
        <img @click="goAnswerDetail()" src="@/assets/img/answer-big.png" alt="" />
        <div>答题</div>
      </div>
    </div>
    <tabbar></tabbar>
  </div>
</template>

<script>
// @ is an alias to /src
import tabbar from '@/components/tabBar.vue'
import navbar from '@/components/navBar.vue'
import { userExamLoad, userExamAdd, searchResultExamOne } from '@/api/api.js'
import { Toast } from 'vant'

export default {
  name: 'answer',
  components: {
    tabbar,
    navbar
  },
  data() {
    return {
      // 答题ID
      ansId: '',
      // 考试ID
      examId: '',
      userId: '',
      userExamId: '',
      from: 'scan',
      //标题
      examName: '',
      //用于判断答题按钮是否可点击的旗标
      flag: true,
      // 用于判断当前答题是否已结束的旗标
      finish: false
    }
  },
  mounted() {
    if (this.$route.query.from) {
      this.from = this.$route.query.from
    }
    // 截取本地url上的ansId
    this.interceptUrl()
  },
  methods: {
    // 截取本地url上的ansId
    interceptUrl() {
      var url = window.location.href
      // var url = "http://localhost:8082/answer?ansId=99665046816161794&userId=3&code=111&state=321";
      this.winUrl = url
      // 如果是首页来的
      if (this.$route.query.from == 'home') {
        // 查询最近一次考试记录
        this.queryRecord()
      }
      // 如果是扫码进来的，则从路径上截取ansId。
      else if (url.indexOf('?') != -1) {
        var str = url.substr(1)
        var s = str.split('=')
        var strs = s[1].split('&')
        // ansId
        this.ansId = strs[0]
        var strs2 = s[2].split('&')
        // userId
        this.userId = strs2[0]
        // 查询考试详情接口
        this.checkExamDetails()
      }
    },
    // 查询考试详情接口
    checkExamDetails() {
      userExamLoad({
        id: this.ansId
      }).then((res) => {
        if (res.data.code == 0) {
          // 给标题赋值
          this.examName = res.data.data.name
          this.examId = res.data.data.id
          // 移动端用户考试
          this.$nextTick(() => {
            this.mUserTest()
          })
        } else {
          Toast(res.data.msg)
          if (res.data.msg == '当前答题已结束！') {
            this.flag = false
            this.finish = true
            this.examName = res.data.msg
          }
        }
      })
    },

    // 移动端用户考试
    mUserTest() {
      if (
        localStorage.getItem('nickname') == undefined ||
        localStorage.getItem('nickname') == null ||
        localStorage.getItem('headImgUrl') == undefined ||
        localStorage.getItem('headImgUrl') == null ||
        localStorage.getItem('openId') == undefined ||
        localStorage.getItem('openId') == null ||
        localStorage.getItem('openId') == 'undefined'
      ) {
        Toast('未获取到微信头像或昵称，请重新登录')
        return
      }
      userExamAdd({
        examId: this.examId,
        openId: localStorage.getItem('openId'),
        wxAvatarUrl: localStorage.getItem('headImgUrl'),
        wxName: localStorage.getItem('nickname')
      }).then((res) => {
        if (res.data.code == 0) {
          if (res.data.data.id == null || res.data.data.id == '') {
            Toast('未获取到考试人员信息')
            this.from = 'home'
            this.queryRecord()
          }
          this.userExamId = res.data.data.id
        } else {
          Toast(res.data.msg)
          this.from = 'home'
          this.queryRecord()
        }
      })
    },

    // 查询最近一次考试记录
    queryRecord() {
      searchResultExamOne({
        openId: localStorage.getItem('openId')
      }).then((res) => {
        if (res.data.code == 0) {
          this.userExamId = res.data.data.id
          // 给标题赋值
          this.examName = res.data.data.examName
        } else {
          Toast(res.data.msg)
          this.examName = res.data.msg
          this.flag = false
        }
      })
    },
    // 跳转答题页
    goAnswerDetail() {
      if (this.flag == false) {
        return
      }
      this.$router.push({
        path: '/answerDetail',
        query: {
          examId: this.examId,
          userId: this.userId,
          userExamId: this.userExamId,
          from: this.from
        }
      })
    }
  }
}
</script>
<style scoped>
.title {
  width: 100%;
  height: 218px;
  position: relative;
}

.title-detail {
  width: 100%;
  text-align: center;
  position: absolute;
  color: #333333;
  font-size: 32px;
  font-family: Songti SC;
  font-weight: bold;
  top: 50%;
  transform: translateY(-50%);
}

.line {
  width: 100%;
  height: 20px;
  background: #faf9fb;
}

.sign {
  text-align: center;
}

.sign img {
  width: 300px;
  height: 300px;
  margin-top: 160px;
}

.sign div {
  margin-top: 40px;
  font-size: 32px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #505050;
}
</style>