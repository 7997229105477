<template>
<!-- 组件：顶部 NavBar 导航栏 -->
  <div class="">
    <van-nav-bar
      :title="title"
    />
          <!-- left-arrow
      @click-left="onClickLeft"
    {{leftArrow}} -->
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  props: {
    title: {
      type: String,
    }
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
  },
};
</script>
<style>
.van-nav-bar__content{
    background: #E03838;
    height: 100px;
}
.van-nav-bar__title{
    color: white;
    font-size: 32px;
    font-family: Microsoft YaHei;
}
.van-nav-bar .van-icon{
    color: white;
}
</style>
